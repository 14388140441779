@font-face {
  font-family: 'CWDangamAsac-Bold';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/CWDangamAsac-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);

:root {
  --title-font: 'CWDangamAsac-Bold';
  --content-font: 'Spoqa Han Sans Neo', 'sans-serif';
  --border-color: rgb(215, 215, 212);
}

a {
  text-decoration: none;
  color: inherit;
}

.container {
  padding: 200px 5em;
  min-height: 100vh;
  width: auto;
  background: linear-gradient(aliceblue, beige);
  font-family: var(--content-font);
  font-weight: 500;
  letter-spacing: -1px;
  font-size: 18px;
}

.transitions-wrapper {
  position: relative;
  overflow: hidden;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
}
.right-enter {
  transform: translateX(100%);
}

.right-enter-active {
  transform: translateX(0);
  transition: transform 300ms ease;
}

.right-exit {
  transform: translateX(0);
}

.right-exit-active {
  transform: translateX(-100%);
  transition: transform 300ms ease;
}

.App {
  margin: 0;
  padding: 0;
}

.headerBox {
  margin: 25px 0;
  display: flex;
  position: fixed;
  justify-content: center;
  width: 100%;
  z-index: 5;
  top: -120px;
  transition: top 0.2s ease;
}

.headerBox.show {
  top: 0;
}

.header {
  border: 1px solid var(--border-color);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  width: max-content;
  margin: 0 auto;
  padding: 0 25px;
  font-family: var(--title-font);
  font-size: 25px;
  align-items: center;
  background-color: beige;
}

.header > div {
  margin: 0 10px;
}

.headerLogo {
  display: flex;
  align-items: center;
  width: 70px;
  height: 100%;
  overflow: hidden;
  box-sizing: content-box;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

}

.headerItems {
  display: flex;
  list-style: none;
  padding: 0;
  border-left: 2px solid var(--border-color);
  padding-left: 20px;
}

.headerItems > li {
  margin: 0 10px;
}

.Notfound {
  width: 100%;
  height: 100vh;
}

.NfInfo {
  color: black;
  font-size: 5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  letter-spacing: -1px;
  font-family: var(--title-font);
}

.NfInfo > span {
  font-size: 0.25em;
  font-family: var(--content-font);
  margin-top: 10px;
}

.profile {
  border: 2px solid var(--border-color);
  display: flex;
  min-width: 1000px;
  border-radius: 30px;
  overflow: hidden;
  padding: 2em;
  background-color: rgb(248, 248, 235);
}

.profileImg {
  overflow: hidden;
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  border-radius: 30px;
  border: 2px solid var(--border-color);
}

.profileImg > img {
  display: block;
  width: 500px;
  margin: 0 auto;
  object-fit: cover;
  transform: translateY(0px);
}

.profileTitle {
  font-family: var(--title-font);
  position: absolute;
  white-space: nowrap;
  top: 300px;
  left: 300px;
  color: white;
  font-size: 90px;
  filter: drop-shadow(0 0 1rem black);
}

.profileDesc {
  word-break: keep-all;
  box-sizing: border-box;
  margin-left: 30px;
}

.profileDesc > p {
  font-size: 20px;
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 15em 2em;
    height: max-content;
  }

  .headerBox {
    width: 0 auto;
  }

  .header {
  }

  .profile {
    width: auto;
    display: block;
    min-width: auto;
  }

  .profileImg {
    width:  auto;
    height: 250px;
  }

  .profileTitle {
    position: absolute;
    top: 17vh;
    left: 30vw;
    color: black;
    filter: none;
  }

  .profileDesc {
    margin: 0;
  }
}